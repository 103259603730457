<template>
    <nav class="navbar navbar-expand-lg navbar-light bg-light nav-bar-wrapper side-padding"
         v-bind:class=" { 'navbarOpen': show }">
        <a class="navbar-brand" href="#">
             <span class="mali-header">
           <span class="mali-title">Mali</span>
           Advocates
       </span>
        </a>
        <button class="navbar-toggler" data-target="#navbarSupportedContent" data-toggle="collapse" type="button"
                @click.stop="toggleNavbar()">
            <span class=""><i aria-hidden="true" class="fa fa-bars"></i></span>
        </button>
        <div id="navbarSupportedContent" class="collapse navbar-collapse" v-bind:class="{ 'show': show }">
            <ul class="navbar-nav mr-auto">
                <li class="nav-item active">
                    <a class="nav-link" href="#about-us">About us</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" href="#our-expertise">Our Expertise</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" href="#careers">Careers</a>
                </li>
            </ul>
            <a class="contact-us-button" href="#contact-us">Contact</a>
        </div>
    </nav>

</template>

<script>
export default {
    name: "NavBar",
    data() {
        return {
            show: false
        }
    },
    methods: {
        toggleNavbar() {
            this.show = !this.show
        }
    }
}
</script>

<style lang="scss" scoped>
.nav-bar-wrapper {
    display: flex;
    justify-content: space-between;
    padding-top: 20px;
    padding-bottom: 15px;
    @media only screen and (max-width: 992px) {
        padding-top: 5px;
    }

    ul {
        list-style-type: none;
        margin-left: 20%;

        @media only screen and (max-width: 992px) {
            margin-left: 0;
        }

        li {
            display: inline;
            margin-left: 64px;
            font-weight: 400;
            font-size: 18px;
            color: rgb(16, 32, 48);

            @media only screen and (max-width: 992px) {
                margin-left: 14px;
            }

            a {
                color: rgb(16, 32, 48);

                &:hover {
                    color: rgb(147, 211, 50);
                }
            }
        }
    }

    .mali-header {
        font-size: 26px;
        font-weight: 300;
        margin-left: -60px;

        @media only screen and (max-width: 992px) {
            margin-left: 0;
        }

        .mali-title {
            font-weight: 700;
        }
    }

    .contact-us-button {
        background-color: rgb(16, 32, 48);
        padding: 9px 36px;
        border-radius: 50px;
        max-height: 45px;
        margin-right: -80px;
        color: white;
        text-decoration: none;

        @media only screen and (max-width: 992px) {
            margin-bottom: 30px;
        }



        &:hover {
            background-color: rgb(147, 211, 50);
        }

        a {
            color: white;

            &:hover {
                color: white;
            }
        }
    }


}

.navbar-light .navbar-toggler {
    color: rgb(147, 211, 50);
    border-color: rgba(0, 0, 0, 0.1);
    font-size: 25px;
}


</style>
