<template>
    <div class="splash-section">
        <section class="splash-section__text">
            <p>We are <strong><span class="splash-section__green-text">continuously advancing</span></strong> our knowledge of new developments in the industries and areas we practice.</p>
        </section>
    </div>
</template>

<script>
export default {
    name: "SplashSection",
}
</script>

<style scoped lang="scss">

    .splash-section {
        font-size: 16px;
        //font-size: 2.3rem;
        line-height: 1.5;
        -webkit-font-smoothing: antialiased;
        box-sizing: border-box;
        width: 100%;
        height: 675px;
        background-image: url("/images/splash_image-22-03-2023.jpg");
        //background-position: center;
        background-repeat: no-repeat;
        background-size: cover;

        @media screen and (min-width: 568px) {
            //font-size: 1.3rem;
            height: 505px;
        }

        .splash-section__text {
            font-weight: 300;
            line-height: 1.3;
            //height: 100%;
            display: flex;
            align-items: center;
            //justify-content: end;
            float: right;
            text-align: center;
            width: 100%;
            padding: 7%;
            letter-spacing: 0.9px;
            margin-top: 25%;

            p {
                font-size: 2.2em;
            }

            @media screen and (min-width: 568px) {
                height: unset;
                align-items: unset;
                text-align: right;
                width: 80%;
                //padding: 0 7% 0 0;
                padding: 0 6% 0 0;
            }

            @media screen and (min-width: 1440px) {
                margin-top: 15%;
                width: 50%;
            }

            .splash-section__green-text {
                font-weight: 700;
            }

        }

    }

</style>
