<template>
    <div>
        <div class="hero">
            <div class="hero-text side-padding">
                <p class="welcome">Welcome to Mali</p>
                <div class="inspire-text">
                    <span>We build relationships <br> that inspire success</span>
                </div>
                <div class="find-lawyer">
                    <span><i class="fa fa-arrow-right"></i></span> Find a Lawyer
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "Banner"
}
</script>

<style scoped lang="scss">
.hero {
    height: 80vh;
    background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url('/images/banner_image-22-03-2023.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    margin: 0;
    width: 100%;

    .hero-text {
        @media only screen and (max-width: 600px) {
            padding-top: 30%;
        }
        @media only screen and (min-width: 600px) {
            padding-top: 30%;
        }


        @media only screen and (min-width: 768px) {
            padding-top: 25%;
        }

        @media only screen and (min-width: 992px) {
            padding-top: 20%;
        }

        @media only screen and (min-width: 1200px) {
            padding-top: 20%;
        }

        @media only screen and (min-width: 1400px) {
            padding-top: 20%;
        }


        .find-lawyer {
            color: white !important;
            margin-top: 42px;
            font-size: 20px;
            font-weight: 300 ;
            .fa {
                padding: 18px;
                font-size: 13px;
                border-radius: 50%;
                background-color: white;
                color: rgb(16, 32, 48);
                margin-right: 15px;

                &:hover {
                    background-color: rgb(147, 211, 50);
                }
            }

        }

        .welcome {
            color: white;
            font-size: 20px;
            line-height: 0;
            font-weight:300;
        }

        .inspire-text {
            span {
                color: white !important;
            }
            font-size: 65px;
            letter-spacing: 2px;
            font-weight: 300;
            line-height: 0.9;
            text-decoration-thickness: 0.1em;
            padding-top: 56px;

            @media only screen and (max-width: 600px) {
                font-size: 33px;
                padding-top: 40px;
            }

            @media only screen and (min-width: 600px) {
                font-size: 40px;
            }


            @media only screen and (min-width: 768px) {
                font-size: 40px;
            }


            @media only screen and (min-width: 992px) {
                font-size: 45px;
            }

            @media only screen and (min-width: 1200px) {
                font-size: 65px;
            }


        }
    }

}
</style>
