<template>
    <div class="careers side-padding d-flex flex-column flex-xl-row">
        <div class="careers__text">
            <section class="careers__text__header">
                <h1>Become a part of the <br class="d-none d-lg-block"/>Mali Team</h1>
            </section>
            <section class="careers__text__body">
                <p>We focus on creating <strong>Efficiency</strong> with our clients. We work <br class="d-none d-xl-block"/>hard to clearly understand our clients' every need.</p>
                <div class="apply-link">
                    <a href="https://careers.cytonn.com/jobs/2349"><i class="apply-icon fa-solid fa-arrow-right"></i>Apply here</a>
                </div>
            </section>
        </div>

        <div class="careers__image">
            <img src="/images/join_the_team-22-03-2023.jpg">
        </div>
    </div>
</template>

<script>
export default {
    name: "Careers",
}
</script>

<style scoped lang="scss">


    img {
        display: block;
        max-width: 100%;
    }

    .careers {
        font-size: 16px;
        //font-size: 1.3rem;
        line-height: 1.5;
        -webkit-font-smoothing: antialiased;
        width: 100%;
        padding-top: 7%;
        //padding-bottom: 7%;
        //display: flex;
        justify-content: space-between;


        .careers__text {
            width: 100%;

            //@media screen and (min-width: 568px) {
            //    width: 75%;
            //}

            @media screen and (min-width: 1440px) {
                width: 60%;
            }

            @media screen and (min-width: 2560px) {
                width: 50%;
            }

            .careers__text__header {
                margin-top: 15%;
                margin-bottom: 15%;


                h1 {
                    font-weight: 300;
                    font-size: 3em;
                    line-height: 1;

                }

                @media screen and (min-width: 568px) {
                    margin-top: 10%;
                    margin-bottom: 10%;
                }

                @media screen and (min-width: 1024px) {
                    margin-top: unset;
                    margin-bottom: unset;
                }

                @media screen and (min-width: 1440px) {
                    h1 {
                        font-size: 2.5em;
                        //font-size: 3.5em;
                    }
                }

            }

            .careers__text__body {
                margin: 7% 0;

                @media screen and (min-width: 1024px) {
                    margin: 5% 0;
                }

                @media screen and (min-width: 1440px) {
                    margin: 7% 0;
                }

                p {
                    font-size: 1.3em;
                    line-height: 1.5;
                }

                .apply-link {
                    margin: 15% 0;

                    @media screen and (min-width: 1024px) {
                        margin: 7% 0;
                    }

                    .apply-icon {
                        border: 2px solid;
                        padding: 2.5%;
                        border-radius: 50%;
                        margin-right: 5%;
                        font-size: 0.7em;

                        @media screen and (min-width: 568px) {
                            margin-right: 2%;
                        }

                    }

                    a {
                        color: inherit;
                        cursor: pointer;
                        font-size: 1.5em;
                        text-decoration: none;

                        @media screen and (min-width: 568px) {
                            font-size: 1.3em;
                        }
                    }

                }

            }

        }

        .careers__image {
            display: flex;
            //align-items: center;
            justify-content: center;
            width: 100%;
            margin: 10% 0;

            img {
                height: 364px;
                width: 672px;
            }

            @media screen and (min-width: 568px) {
                margin: 0;
            }

            @media screen and (min-width: 1440px) {
                width: 40%;
                margin: 0;
            }

            @media screen and (min-width: 2560px) {
                width: 50%;
            }

        }

    }

</style>
