<template>

    <div class="contact-us__form-wrapper">

        <form @submit.prevent="submitContactUsForm">
            <div class="title">
                <h2>Contact Us</h2>
            </div>

            <div class="body">

                <div v-if="response_type" :class="[ response_type === 'success' ? 'alert-success' : 'alert-danger' ]" class="alert alert-dismissible fade show" role="alert">
                    <p v-if="response_type === 'success'">Your message has been sent successfully.</p>
                    <p v-if="response_type === 'error'">Sorry. We could not send your message.</p>
                    <button type="button" class="close" @click.prevent="hideMessage">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>

                <input id="first_name" type="text" name="first_name" v-model="form.first_name" placeholder="Full Name" required />
                <span v-if="form_errors['first_name']">
                    <p v-for="error in form_errors['first_name']" :key="Math.random() * Math.random()" class="text-danger small">{{ error }}</p>
                </span>

                <input id="email" type="text" name="email" v-model="form.email" placeholder="Email" required />
                <span v-if="form_errors['email']">
                    <p v-for="error in form_errors['email']" :key="Math.random() * Math.random()" class="text-danger small">{{ error }}</p>
                </span>

                <input id="phone_number" type="number" name="phone_number" v-model="form.phone_number" placeholder="Phone Number" required />
                <span v-if="form_errors['phone_number']">
                    <p v-for="error in form_errors['phone_number']" :key="Math.random() * Math.random()" class="text-danger small">{{ error }}</p>
                </span>

                <textarea id="message" name="message" v-model="form.message" placeholder="Your Message/Query.." rows="3" required></textarea>
                <span v-if="form_errors['message']">
                    <p v-for="error in form_errors['message']" :key="Math.random() * Math.random()" class="text-danger small">{{ error }}</p>
                </span>

            </div>

            <section class="captcha-note">
                <p>This contact form is protected by reCAPTCHA and the Google Privacy Policy and Terms of Service apply.</p>
            </section>

            <div class="submit-buttons">

                <button class="submit" :disabled="button_disabled">Submit</button>

            </div>

        </form>

    </div>

</template>

<script>
export default {
    name: "ContactUsForm",

    data() {
        return {
            form: {},
            response_type: null,
            form_errors: {},
            button_disabled: false,
        }
    },

    methods: {
        hideMessage() {
            this.response_type = null
        },

        submitContactUsForm() {
            this.button_disabled = true
            this.response_type = null
            this.form_errors = {}

            axios.post('api/contact-us', this.form)
                .then(response => {
                    this.response_type = 'success'
                    this.form = {}
                })
                .catch(error => {
                    this.response_type = 'error'
                    if (error.response.status == 422) {
                        this.form_errors = error.response.data.errors
                    }
                })
                .then(() => this.button_disabled = false)
        }
    }
}

</script>

<style scoped lang="scss">

    .contact-us__form-wrapper {
        form {
            display: flex;
            flex-direction: column;
            margin-bottom: 20%;

            @media screen and (min-width: 992px) {
                margin-bottom: 5%;
            }

            .title {
                h2 {
                    font-size: 2.2em;
                    line-height: 1;
                    margin-bottom: 4%;
                }
            }

            .body {
                display: flex;
                flex-direction: column;
            }

            .captcha-note {
                margin-top: 3%;

                p {
                    font-size: 13px;
                    margin-bottom: 0;
                }
            }

            .submit-buttons {
                margin-top: 5%;

                button.submit {
                    border: none;
                    padding: 5% 10%;
                    text-align: center;
                    text-decoration: none;
                    display: flex;
                    cursor: pointer;
                    border-radius: 50px;
                    font-size: 1.25rem;
                    height: 65px;
                    width: 150px;
                    align-items: center;
                    justify-content: center;

                     &:hover {

                     }

                }
            }
        }
    }


</style>
