<template>
    <div id="about-us" class="about-wrapper side-padding">
        <div>
            <h2 class="about-mali-text">About Mali</h2>
            <div class="about-mali row">
                <section class="about-section col-md-7 col-sm-12">
                    <p>Your go-to law firm for <span class="emphasized-text">quality</span> legal
                        services. We aim to meet and <span class="emphasized-text">exceed client expectations.</span>
                    </p>
                </section>

                <div class="description col-md-5 col-sm-12">
                    <p> Mali Advocates LLP is a middle-sized firm providing a comprehensive range of legal services and
                        solutions
                        for domestic and international clients.</p>

                    <p>The firm is focused on providing clients with high quality legal services throught our team of
                        highly trained ,
                        skilled and experienced advocates.</p>
                </div>
            </div>
        </div>

        <section>
            <div class="our-values-title row">
                <img class="icon" src="/icons/icon6.png"/>
                <h2 class="our-value-text"> Our Values</h2>
            </div>

            <carousel class="values row" v-bind:per-page-custom=[[200,1],[780,3]] :autoplay=true paginationColor= #051b2c :paginationSize=15 :autoplayTimeout=3000  paginationActiveColor=#71d227>
                <slide class="col-md-4 col-sm-12 col-xs-12">
                    <h2> Quality</h2>
                    <p>We focus on creating long lasting and <span class="emphasize">Quality</span> with our clients.
                        We work hard to clearly understand our clients' every need</p>
                </slide>
                <slide class="col-md-4 col-sm-12 col-xs-12">
                    <h2>Efficiency</h2>
                    <p>We focus on creating <span class="emphasize">Efficiency</span> with our clients.
                        We work hard to clearly understand our clients' every need</p>
                </slide>
                <slide class="col-md-4 col-sm-12 col-xs-12">
                    <h2>Innovation</h2>
                    <p>We focus on creating long lasting and strong <span class="emphasize">Innovations</span> with our clients.
                        We work hard to clearly understand our clients' every need</p>
                </slide>


            </carousel>
        </section>
    </div>
</template>

<script>
import { Carousel, Slide } from 'vue-carousel';
export default {
    name: "AboutUs",
    components: {
        'carousel': Carousel,
        'slide': Slide
    },
}
</script>

<style scoped lang="scss">

@mixin paragraphStyle {
    margin-bottom: 40px;
    //line-height: 30px;
    color: rgb(43, 64, 69);
    font-weight: 450;
    //font-size: 1.3em;
    line-height: 1.4;
    font-size: 20px;


}

.about-section {
    p{
        color: rgb(173, 181, 188);
    }
    font-size: 38px;
    font-weight: 300;
}

.values {
    margin-top: 40px;
}

.about-wrapper {
    margin-top: 60px;
    margin-bottom: 80px;
    .about-mali-text {
        color: rgb(173, 181, 188);
        letter-spacing: 4px;
        text-transform: uppercase;
        font-size: 15px;
        font-weight: 350;
    }

    .description {
        p {
            @include paragraphStyle;
        }
    }
}

.emphasized-text {
    font-weight: 700;
    font-size: 35px;
    letter-spacing: 2px;
    color: black;
}

.our-values-title {
    display: flex;
    margin-bottom: 20px;

    .icon {
        height: 55px;
        margin-right: 25px;
    }
}

.our-value-text {
    font-size: 40px;
    font-weight: 400;
    letter-spacing: 1px;
    margin-top: 5px;
}

.values {
    h2 {
        margin-bottom: 40px;
        color: rgb(43, 64, 69);
        letter-spacing: 1.5px;
    }

    p {
        @include paragraphStyle;
        .emphasize{
            color:black;
            font-weight: 600;
            font-size:22px;
        }
    }
}

.VueCarousel-dot {
    padding: 2px;
background-color: #584e4e !important;
height: 20px !important;
width: 20px !important;
}

.VueCarousel-dot--active{
    background-color: rgb(147, 211, 50);
}

</style>
