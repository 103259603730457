import router from './routes/index'
import store from './store/index'
import VueRouter from 'vue-router'

import Vue from "vue";
require('./bootstrap');
require('./components');
window.Vue = require('vue');

Vue.use(VueRouter)


const app = new Vue({
    el: '#app',
    store,
    router: new VueRouter(router),
});
