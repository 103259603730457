<template>
    <div class="our-expertise side-padding">
        <section class="our-expertise__text">
            <div class="our-expertise__text__header d-flex flex-column flex-md-row">
                <img src="/icons/icon5.png">
                <h2>Areas of Expertise</h2>
            </div>
            <div class="our-expertise__text__body">
                <p>We focus on creating long lasting and <strong>Quality</strong> with our clients. We work hard to clearly understand our clients' every need.</p>
            </div>
        </section>

        <section class="our-expertise__cards">
            <expertise-card>
                <template #expertise-image>
                    <img src="/icons/icon1.png">
                </template>

                <template #expertise-text>
                    <p><strong>Dispute Resolution</strong></p>
                </template>
            </expertise-card>

            <expertise-card>
                <template #expertise-image>
                    <img src="/icons/icon2.png">
                </template>

                <template #expertise-text>
                    <p><strong>Employment & Labor Law</strong></p>
                </template>
            </expertise-card>

            <expertise-card>
                <template #expertise-image>
                    <img src="/icons/icon3.png">
                </template>

                <template #expertise-text>
                    <p><strong>Real Estate & Conveyancing</strong></p>
                </template>
            </expertise-card>

            <expertise-card>
                <template #expertise-image>
                    <img src="/icons/icon4.png">
                </template>

                <template #expertise-text>
                    <p><strong>Corporate & Commercial</strong></p>
                </template>
            </expertise-card>
        </section>
    </div>
</template>

<script>
import ExpertiseCard from "../Partials/ExpertiseCard";
export default {
    name: "OurExpertise",
    components: {
        ExpertiseCard
    }
}
</script>

<style scoped lang="scss">

    img {
        display: block;
        max-width: 100%;
    }

    .our-expertise {
        font-size: 16px;
        line-height: 1.5;
        -webkit-font-smoothing: antialiased;
        box-sizing: border-box;
        margin-bottom: 15%;
        width: 100%;

        @media screen and (min-width: 568px) {
            margin-bottom: 8.5%;
        }

        .our-expertise__text {

            .our-expertise__text__header {
                //display: flex;
                align-items: center;
                justify-content: center;
                text-align: center;

                h2 {
                    font-size: 2.5em;
                    font-weight: 500;
                }

                img {
                    margin: 0 25px;
                    height: 75px;
                    width: 75px;
                }
            }

            .our-expertise__text__body {
                text-align: center;
                width: 100%;
                margin: 5% auto;

                p {
                    font-size: 1.25em;
                    line-height: 1.3;
                }

                @media screen and (min-width: 568px) {
                }

                @media screen and (min-width: 1440px) {
                    margin: 3% auto;
                    width: 50%;
                }
            }

        }

        .our-expertise__cards {
            width: 100%;
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
            margin-top: 15%;
            gap: 25px 20px;

            p {
                line-height: unset;
                font-weight: 500;
            }

            @media screen and (min-width: 568px) {
                margin-top: 5.5%;
            }

            @media screen and (min-width: 1440px) {
                row-gap: 0;
            }
        }

    }

</style>
