<template>

    <div class="expertise-card">
        <div class="expertise-card__icon">
            <slot name="expertise-image"></slot>
        </div>

        <div class="expertise-card__body">
            <slot name="expertise-text"></slot>
        </div>
    </div>

</template>

<script>
export default {
    name: "ExpertiseCard"
}
</script>

<style scoped lang="scss">

    .expertise-card {
        font-size: 1.3rem;
        line-height: 1.5;
        -webkit-font-smoothing: antialiased;
        box-sizing: border-box;
        box-shadow: 0 0 10px 0px rgba(0, 0, 0, 0.3);
        border-radius: 10px;
        padding: 15px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        //min-height: 168px;
        min-height: 126px;

        .expertise-card__icon {
            img {
                height: 55px;
                width: 60px;

                //height: 70px;
                //width: 70px;

                /*
                    max-height: 70px;
                    max-width: 70px;
                 */
                //max-height: 80px;
                //max-width: 80px;
                //height: auto;
                //width: auto;
                //display: block;
                object-fit: contain;
            }
        }
        .expertise-card__icon, .expertise-card__body {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 1% 0;
        }

        .expertise-card__body {
            text-align: center;

            p {
                font-size: 1em;
                //font-size: 1.35em !important;
                margin-bottom: 0;
            }
        }
    }


</style>
