<template>
    <div class="footer side-padding d-flex flex-column-reverse flex-xl-row">
        <section class="footer__text mt-5 mt-md-0">
            <h4>&#169; Copyright Mali Advocates LLP All Rights Reserved</h4>
        </section>
        <section class="footer__social-media-icons">
            <a class="icon">
                <i class="fa-brands fa-linkedin-in"></i>
            </a>
            <a class="icon">
                <i class="fa-brands fa-twitter"></i>
            </a>
            <a class="icon">
                <i class="fa-brands fa-facebook-f"></i>
            </a>
            <a class="icon">
                <i class="fa-brands fa-instagram"></i>
            </a>
        </section>
    </div>
</template>

<script>
export default {
    name: "PageFooter",
}
</script>

<style scoped lang="scss">

    .footer {
        //display: flex;
        font-size: 1.3rem;
        line-height: 1.5;
        -webkit-font-smoothing: antialiased;
        box-sizing: border-box;
        width: 100%;
        padding-top: 20px;
        padding-bottom: 20px;

        .footer__text {
            width: 100%;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            text-align: center;
            margin-bottom: 3%;
            padding-left: 1.5%;

            @media screen and (min-width: 1440px) {
                width: 50%;
                padding-left: 3.5%;
                text-align: unset;
                margin-bottom: 0;
            }


            h4 {
                margin-bottom: 0;
            }

        }

        .footer__social-media-icons {
            width: 100%;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            margin-bottom: 3%;

            @media screen and (min-width: 1440px) {
                width: 50%;
                padding-right: 6.25%;
                justify-content: flex-end;
                margin-bottom: 0;
            }

            .icon {
                cursor: pointer;
                display: flex;
                align-items: center;
                justify-content: center;
                margin: 0 10px;
                width: 50px;
                height: 50px;
                border-radius: 50%;
                font-size: 1.2em;

                &:hover {
                    text-decoration: none;
                }

            }
        }
    }

</style>
