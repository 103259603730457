<template>
    <router-view :key="new Date().getTime()"></router-view>

</template>

<script>
export default {
    name: "App"
}
</script>

<style scoped>

</style>

