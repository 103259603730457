<template>
    <div class="contact-us side-padding">
        <div class="contact-us__body">
            <div class="contact-us__text">
                <div class="contact-us__text__header">
                    <h2>We are pleased to connect with you <br class="d-none d-xl-block" /><span class="bold-text">in-person</span>, <span class="bold-text">by phone</span>, by <span class="bold-text">email</span> or <span class="bold-text">24/7 on <br class="d-none d-xl-block" />our website.</span> We look forward to learning <br class="d-none d-xl-block" />how we can help you.</h2>
                </div>
                <div class="contact-us__text__body">
                    <h3>Where to find us</h3>
                    <div class="address d-flex flex-column flex-md-row">
                        <section class="address-entry">
                            <p>The Chancery, Valley road</p>
                            <p>Nairobi, Kenya.</p>
                        </section>
                        <br class="d-xl-none" />
                        <section class="address-entry">
                            <p>Info@maliadvocates.com</p>
                            <p>P.O Box 100158-00101</p>
                            <p>Nairobi, Kenya.</p>
                        </section>
                    </div>
                </div>
            </div>

            <div class="contact-us__form">
                <contact-us-form></contact-us-form>
            </div>

            <div class="scroll-to-top">
                <a href="#top"><i class="fa-solid fa-chevron-up"></i></a>
            </div>
        </div>
    </div>
</template>

<script>
import ContactUsForm from "../Partials/ContactUsForm";
export default {
    name: "ContactUs",
    components: {
        ContactUsForm,
    }
}
</script>

<style scoped lang="scss">
    .contact-us {
        font-size: 16px;
        //font-size: 1.3rem;
        line-height: 1.5;
        -webkit-font-smoothing: antialiased;
        box-sizing: border-box;
        margin-top: 3%;
        width: 100%;
        position: relative;
        overflow: hidden;

        .contact-us__body {
            border-radius: 10px;
            padding: 15% 5% 4%;
            display: flex;
            flex-direction: column;

            @media screen and (min-width: 1440px) {
                padding: 7% 5% 4%;
                display: grid;
                grid-template-columns: repeat(13, 1fr);
            }

            .contact-us__text {
                grid-column: 1 / 9;

                .contact-us__text__header {
                    .bold-text {
                        font-weight: 600;
                    }

                    h2 {
                        font-size: 2em;
                        font-weight: 300;
                    }

                    @media screen and (min-width: 1440px) {
                        h2 {
                            font-size: 1.7em;
                        }
                    }

                    @media screen and (min-width: 1440px) {
                        h2 {
                            font-size: 2.3em;
                        }
                    }

                }

                .contact-us__text__body {
                    margin: 11% 0;
                    font-size: 1.25em;


                    h3 {
                        font-size: 1.25em;
                        font-weight: 600;
                    }

                    .address {
                        //display: flex;
                        width: 100%;
                        margin: 3% 0;

                        .address-entry {
                            width: 100%;
                            word-break: break-word;

                            @media screen and (min-width: 568px) {
                                width: 50%;
                            }
                        }

                        p {
                            margin-bottom: 0;
                        }
                    }

                }
            }

            .contact-us__form {
                grid-column: 9 / -1;

                margin-top: 15%;
                //display: grid;
                //grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
                //gap: 0 20px;

                @media screen and (min-width: 568px) {
                    margin-top: unset;
                }

            }

        }

        .scroll-to-top {
            height: 50px;
            width: 50px;
            border-radius: 50%;
            position: absolute;
            right: 1%;
            bottom: 10%;

            @media screen and (min-width: 568px) {
                right: 5%;
                bottom: 13%;
            }

            @media screen and (min-width: 768px) {
                bottom: 20%;
            }

            @media screen and (min-width: 1024px) {
                right: 7%;
            }

            @media screen and (min-width: 1440px) {
                bottom: 25%;
            }

            a {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                height: 100%;
                text-decoration: none;
                border-radius: 50%;

            }

            i {
                font-size: 1.25rem;
            }
        }

    }

</style>
