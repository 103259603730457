<template>
<div>

    <NavBar/>
    <Banner/>
    <about-us/>

    <div id="our-expertise">
        <our-expertise></our-expertise>
    </div>

    <div id="the-team">
        <the-team></the-team>
    </div>

    <div id="splash-section">
        <splash-section></splash-section>
    </div>

    <div id="careers">
        <careers></careers>
    </div>

    <div id="contact-us">
        <contact-us></contact-us>
    </div>

    <div id="footer">
        <page-footer></page-footer>
    </div>

</div>
</template>

<script>
import NavBar from "./NavBar";
import Banner from "./Banner";
import AboutUs from "./AboutUs";
import OurExpertise from "./OurExpertise";
import TheTeam from "./TheTeam";
import SplashSection from "./SplashSection";
import Careers from "./Careers";
import ContactUs from "./ContactUs";
import PageFooter from "./PageFooter";

export default {
    name: "LandingPage",
    components: {
        NavBar,
        Banner,
        AboutUs,
        OurExpertise,
        TheTeam,
        SplashSection,
        Careers,
        ContactUs,
        PageFooter,
    }
}
</script>

<style lang="scss">
@import "../../../sass/variables";
</style>
