import LandingPage from "../components/LandingPage/LandingPage";


export default {
    mode: 'history',

    routes: [
        {
            path: '/',
            name: 'landing',
            component: LandingPage,
        },
        {
            path: '',
            name: 'landing',
            component: LandingPage,
        }
    ]
}
