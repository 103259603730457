<template>
    <div class="the-team side-padding">
        <div class="the-team-header">
            <h2 class="">The Mali Team</h2>
        </div>

        <div class="carousel-wrapper">
            <carousel class=""
                  :per-page-custom=[[0,1],[568,2]]
                  :paginationEnabled=false
                  :navigationEnabled=true
                  :navigationClickTargetSize="0"
                  :navigationNextLabel="`<span class='navigation-arrow'><i class='fa-solid fa-arrow-right'></i></span>`"
                  :navigationPrevLabel="`<span class='navigation-arrow'><i class='fa-solid fa-arrow-left'></i></span>`"
                  >
            <slide>
                <team-card
                    image-src="images/noah_o_randiek_mali_advocates_300x357.jpeg"
                    name="Noah O. Randiek"
                    job-position="Partner"
                />
            </slide>
            <slide>
                <team-card
                    image-src="images/mitchelle_d_asiyo_mali_advocates_300x357.jpg"
                    name="Mitchelle D. Asiyo"
                    job-position="Associate"
                />
            </slide>
        </carousel>
        </div>
    </div>
</template>

<script>
import TeamCard from "../Partials/TeamCard";
import { Carousel, Slide } from 'vue-carousel';
export default {
    name: "TheTeam",
    components: {
        'team-card': TeamCard,
        'carousel': Carousel,
        'slide': Slide
    },
}
</script>

<style lang="scss" scoped>
.the-team {
    margin-bottom: 7.5%;

    .the-team-header {
        margin-bottom: 4%;
    }

    .VueCarousel-slide {
        @media screen and (max-width: 568px) {
            display: flex;
            justify-content: center;
        }
    }

}

</style>
