<template>
    <div class="team-card">
        <img class="image" :src="imageSrc" alt="Image of mali advocates team member" />
        <section class="team-card_info" v-if="name || jobPosition">
            <h2 class="team-card_info__name">{{ name }}</h2>
            <h6 class="team-card_info__jobPosition">{{ jobPosition }}</h6>
        </section>

        <slot></slot>

    </div>

</template>

<script>
export default {
    name: "TeamCard",
    props: ['imageSrc', 'name', 'jobPosition']
}
</script>

<style lang="scss" scoped>
.team-card {
    min-width: 200px;
    max-width: 280px;
    min-height: 280px;

    img {
        height: auto;
        width: 200px;
    }

    .team-card_info {
        margin: 5% 0;

        .team-card_info__name {
            margin: 4% 0;
        }

        .team-card_info__jobPosition {
            margin: 4% 0;
        }
    }

}

@media screen and (min-width: 768px) {
    .team-card {

        img {
            height: auto;
            width: 280px;
        }

    }
}


</style>
